<template>
  <div
    class="main"
    :style="{
      display: 'flex',
      width: '100%',
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
    }"
  >
    <div class="box"></div>
  </div>
</template>

<script>
import image from "@/assets/images/bg.png";
import UserApi from "../../state/api/user_api";
import Cookie from "js-cookie";

export default {
  data() {
    return {
      image: image,
    };
  },
  watch: {},
  mounted() {
    if (!window.MeeyId) {
      // async init
      window.meeyIdAsyncInit = () => {
        this.initMeeyid(window.MeeyId, this.$route.params.page);
      };
    } else {
      this.initMeeyid(window.MeeyId, this.$route.params.page);
    }

    document.addEventListener("visibilitychange", () => {
      console.log("visibilitychange", document.visibilityState);
      window.MeeyId.syncUser()
    });

    window.$("input:visible:first").focus();
  },
  methods: {
    initMeeyid(MeeyId, route) {
      MeeyId.initSDK(null, {
        clientId: "meeyid",
        type: "form",
        divElement: "div.box",
        sso: false,
        route: route,
        // domain: 'meey.dev',
        // affilateId: "asdasdas",
        onReady: () => {},
        onUser: async (user) => {
          console.trace("example.onUser -> user", user);
          if (user) {
            const _auth = user;
            window.localStorageX.setItem("_auth", JSON.stringify(_auth));
            console.log("FE-window.localStorageX", window.localStorageX);
            if (_auth.access_token && _auth.user) {
              try {
                // verify accessToken
                await UserApi.me();
                document.cookie = `token=${_auth.access_token};max-age=${_auth.expires_in};path=/`;
                const clientRedirect = Cookie.get("client");
                console.log("FE-clientRedirect", clientRedirect);
                if (clientRedirect) {
                  const client = JSON.parse(atob(clientRedirect));
                  // login kieu redirect
                  const queryString = Object.keys(client)
                    .map((key) => key + "=" + encodeURIComponent(client[key]))
                    .join("&");
                  location.href = `/sdk/v4/authorize?${queryString}`;
                  Cookie.remove("client");
                  return;
                }
                // save cookie
                console.trace("FE-redirect to userbackend");

                location.href = "/userbackend/users/user-info";
              } catch (e) {
                console.log("FE-error", e);
                window.localStorageX.removeItem("_auth");
              }
            }
          }
        },
        onSignInCompleted: () => {
          console.trace("onSignInCompleted");
        },
        onSignOutCompleted: () => {
          console.trace("onSignOutCompleted");
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 100vh;
}
.box {
  width: 450px;
  margin: auto;
}

// MOBILE
@media screen and (max-width: 500px) {
  .main {
    //  height: 100vh;
    max-height: 100%;
  }
  .box {
    padding: 0;
    width: 100%;
    margin: 0;
    position: relative;
  }
}
</style>
